@import "./colors";

.inline-form {
  .form-group {
    flex-direction: row;

    label {
      min-width: 6rem;
      padding: 0.25rem;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem !important;
  width: 100%;

  label {
    margin-bottom: 0;
    font-size: 90%;
    padding: 0 0 0.25rem 0.25rem;
  }

  .form-input-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .login-error {
    font-size: 90%;
    padding-top: 3px;
  }

  .form-control {
    @include form-input;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  @include form-input;
}