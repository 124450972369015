@import "../../styles/colors";
@import "../../styles/mixins";

@keyframes show-note-content {
  from {
    flex-basis: 70%;
  }

  to {
    flex-basis: 100%;
  }
}

@keyframes summary-note-default {

  // from {
  //   flex-basis: 31%;
  // }
  // to {
  //   flex-basis: 0%;
  // }
  from {
    flex-basis: 30%;
    opacity: 1;
  }

  to {
    flex-basis: 0;
    opacity: 0;
    transform: scaleX(0);
  }
}

@keyframes plan-collapse {
  from {
    flex-basis: 100%;
  }

  to {
    flex-basis: 70%;
  }
}

@keyframes note-collapse {
  from {
    flex-basis: 0%;
  }

  to {
    flex-basis: 31%;
  }
}

@mixin custom-primary-button {
  @include primary-button;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
}

.day-app-main-column {
  overflow-y: auto;

  .envision-main {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100vh;
    overflow: hidden auto;
    gap: 1rem;
    width: 100%;
    position: relative;
    padding-bottom: 4rem;

    @include larger-than-phone {
      padding-bottom: 1rem;
    }

    .spinner {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .goal-header {
      display: inline-flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.75rem;
      padding-left: 0;

      strong {
        font-size: 2rem;
        line-height: 1;
      }

      span {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .goal-section {
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: max-content;

      .header-skeleton {
        width: 200px;
      }

      &.flex-1 {
        flex: 1;
      }

      .date-selector,
      .datepicker-placeholder {
        align-self: center;
      }

      .datepicker-placeholder {
        width: 200px;
      }

      .date-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // font-size: 1rem;
        gap: 0.5rem;
        color: $primary-color !important;

        @include desktop {
          padding: 0.25rem 0 !important;
        }

        .right-btns {
          display: flex;
          gap: 0.25rem;
        }

        .btn {
          border-radius: 100%;
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          color: $primary-color;
          font-weight: bold;

          &.disabled {
            pointer-events: none;
          }

          >svg {
            position: relative;
          }

          &.prev {
            >svg {
              left: -1px;
            }
          }

          &.next {
            >svg {
              left: 1px;
            }
          }

          &:not(.disabled):hover {
            background-color: #d4d4d480;
          }
        }
      }

      &.bottom {
        hr {
          margin: 0 0 1rem 0;
          height: 2px;
          background: linear-gradient(116.61deg,
              #b3fce2 11.5%,
              #a1e0fc 32.8%,
              #ebc7fc 68.81%,
              #f6e5fe 86.52%);
        }

        .frozen-bar {
          margin: 0 !important;
        }

        .nav-tabs {
          display: none;
        }
      }

      .tab-content {
        flex: 1;

        .tab-pane {
          height: 100%;

          .tab-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            position: relative;
            padding-block: 1rem;

            &.default {
              @include desktop {
                .tab-main {
                  animation: plan-default 300ms ease-in-out forwards;
                }

                .tab-side {
                  animation: note-default 300ms ease-in-out forwards;
                }
              }
            }

            &.plan-full {
              @include desktop {
                .tab-main {
                  animation: plan-full 300ms ease-in-out forwards;
                }

                .tab-side {
                  animation: note-hide 300ms ease-in-out forwards;
                }
              }
            }

            &.note-full {
              justify-content: flex-end;
              height: 70vh;

              @include desktop {
                .tab-main {
                  animation: plan-hide 300ms ease-in-out forwards;
                }

                .tab-side {
                  animation: note-full 300ms ease-in-out forwards;

                  .tox {
                    height: 100% !important;
                  }
                }
              }
            }

            &.collapsed-note {
              justify-content: flex-end;

              @include desktop {
                .tab-main {
                  animation: plan-default 300ms ease-in-out forwards;
                }

                .tab-side {
                  animation: note-collapse 300ms ease-in-out forwards;
                }
              }
            }

            @include desktop {
              flex-direction: row;
            }

            .tab-main {
              flex: none;
              background-color: $primary-gray;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              position: relative;
              border-bottom-left-radius: 0.25rem;
              width: 100%;

              @include desktop {
                padding: 1rem;
              }

              >.form-group {
                padding: 0 !important;
              }

              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                .label-skeleton {
                  max-width: 200px;
                  height: 24px;
                }

                >strong {
                  font-size: 1rem;
                }

                .right-buttons {
                  display: flex;
                  gap: 0.5rem;
                  position: relative;
                  align-items: center;

                  .goal-toggle {
                    display: none;

                    @include desktop {
                      z-index: 1;
                      display: inline-flex;
                      align-items: center;
                      transform: translateX(30%);
                      cursor: pointer;
                    }
                  }

                  .add-button {
                    @include custom-primary-button;

                    >span {
                      display: none;
                      line-height: 1;

                      @include larger-than-phone {
                        display: inline-block;
                      }
                    }
                  }

                  .goal-note-button {
                    display: none;

                    @include desktop {
                      display: inline-flex;
                    }
                  }
                }
              }

              .main-goal-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @include desktop {
                  flex-direction: row;
                }

                .goals {
                  flex: 1;
                  transition: flex-basis 0.3s ease-in-out;
                }

                .summary-note {
                  transition: flex-basis 0.3s ease-in-out;
                }

                &.default {
                  @media (min-width: 60em) {
                    // .goals {
                    //   animation: goals-default 300ms ease-in-out forwards;
                    // }

                    .summary-note {
                      animation: summary-note-default 300ms ease-in-out forwards;
                      display: none;
                    }
                  }
                }

                &.collapsed-note {
                  justify-content: flex-end;

                  @media (min-width: 60em) {
                    .summary-note {
                      animation: note-collapse 300ms ease-in-out forwards;
                    }
                  }
                }
              }

              .rta {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-left: none;

                .add-goal-input {
                  font-size: 14px;
                  outline: none;
                  border: 1px solid $primary-color;
                }
              }

              .goals {
                flex: 1;
                min-height: 50vh;

                >div[data-react-beautiful-dnd-droppable] {
                  display: flex;
                  flex-direction: column;
                }

                .no-data {
                  width: 100%;
                  display: grid;
                  height: 100%;
                  place-content: center;
                }

                .fa-btn {
                  width: 28px;
                  height: 28px;
                  font-size: 14px;
                }

                .goal {
                  >span {
                    display: flex;
                    flex-direction: column;
                    border-radius: 7px;
                  }

                  &:hover {
                    >span {
                      box-shadow: 2px 2px 8px #bb3af7;
                    }
                  }

                  .single-workette-item {
                    margin-bottom: 0;

                    &:hover {
                      box-shadow: none;
                    }
                  }
                }
              }
            }

            .summary-note {
              flex: none;
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              padding: 0.25rem;
              background-color: $primary-gray;
              border-bottom-right-radius: 0.25rem;

              @include desktop {
                padding: 0;
              }

              .header {
                display: flex;
                gap: 0.5rem;
                justify-content: flex-start;

                >.css-bbq5bh {
                  display: none;

                  @include desktop {
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;
                  }
                }
              }

              .quill {
                height: 100%;
                display: flex;
                flex-direction: column;
              }

              &.hidden {
                .ql-editor {
                  p {
                    display: none;
                  }
                }
              }

              &.show {
                .ql-editor {
                  p {
                    animation-name: show-note-content;
                    animation-duration: 1s;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottom-toggle {
      display: flex;
      width: 100%;
      position: relative;
      border-top: 2px solid $primary-color;
      border-bottom: none;
      justify-content: center;

      span {
        padding: 0.25rem 0.5rem;
        font-size: 1rem;
        background-color: $primary-color;
        color: white;
        cursor: pointer;
        border-radius: 0 0 3px 3px;
        transition: background-color 0.3s ease-in-out;
        display: inline-block;
        min-width: 100px;
        text-align: center;

        &:hover {
          background-color: $primary-color;
        }
      }

      &.visible {
        border-top: none;
        border-bottom: 2px solid $primary-color;

        span {
          border-radius: 3px 3px 0 0;
        }
      }
    }
  }
}

.associates-status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-top: 1px solid #3d67f680;

  .associates-status-container {
    width: 100%;
    display: grid;
    padding-top: 0.5rem;
    gap: 0.5rem;

    @include larger-than-phone {
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }

    >* {
      min-height: 185px;
      padding: 0.25rem 0.5rem;
      background-color: white;
    }

    .ai-suggestion-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      min-width: 127px;
      background-color: white;

      header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1rem;
        min-height: 28px;

        >strong {
          line-height: 1;
        }
      }

      .recommendations-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1;

        .recommendations {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          >.spinner-grow {
            align-self: center;
            position: relative;
          }

          .recommended-items {
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // background-color: white;
            // padding: 0.5rem;
            // border-radius: 4px;
            // border: 1px solid orange;
            // color: orange;
            // font-size: 14px;
            // font-weight: 500;


            background-color: white;
            padding: 0.25rem 0.5rem;
            border-radius: 4px;
            border: 1px solid orange;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: orange;

            >span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            >svg {
              margin-right: 0.5rem;
              cursor: pointer;
              height: 1.1rem;
              width: 1.1rem;
              padding: 2px;
              border-radius: 100vw;
              position: relative;
              top: 1px;
              transition: 300ms color ease-in-out;
              color: #e0774780;

              &:hover {
                // background-color: #00e880;
                color: orange;
              }
            }
          }
        }
      }

      .ai-suggestion-footer {
        background: white;
        position: sticky;
        bottom: 0;
        padding-block: 0.5rem;

        .add-associate-manually-btn-container {
          display: inline-flex;
          align-items: center;
          color: $primary-color;
          gap: 0.5rem;
          cursor: pointer;
          font-weight: 600;

          .add-associate-manually-btn {
            @include custom-primary-button;
          }

          span {
            line-height: 1;
          }
        }
      }
    }

    .summary-section {
      overflow: unset;

      >header {
        font-weight: 600;
        margin-bottom: 1rem;
        min-height: 30px;
      }

      // li {
      // &.open {
      //   background-color: lightgray;
      // }

      // &.in_progress {
      //   background-color: #ffffed;
      // }

      // &.completeds {
      //   background-color: #b4d7815e;
      // }
      // }
    }
  }
}

.add-associate-manually-btn {
  @include custom-primary-button;
}

.see-btn {
  cursor: pointer;
  padding: 4px 0.5rem;
  background-color: #3d67f6;
  align-self: center;
  color: white;
  border-radius: 4px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-associates-modal {
  .modal-dialog {
    min-width: 80vw;
  }

  .modal-body {
    background-color: #f8f8f8;
  }

  .associate-container-wrapper,
  .view-all-task {
    display: none;
  }

  .associates-status-wrapper {
    border-top: 0;

    .associates-status-collapsible {
      height: 100%;
    }
  }

  .see-btn {
    display: none;
  }
}

.wkt-note-modal {
  .ql-editor {
    height: 72.5vh;
    border-radius: 5px;
  }
}