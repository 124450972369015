.fa-btn {
  width: 26px;
  height: 26px;
  padding: 0 !important;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  will-change: background-color;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
  position: relative;
  pointer-events: inherit;

  > button {
    &:focus {
      box-shadow: none;
    }
  }

  svg {
    margin: 0 !important;
    width: 20px !important;

    > path {
      transition: fill 0.3s ease-in-out;
      will-change: fill;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    svg > path {
      fill: rgb(0, 0, 0) !important;
    }
  }
}

.single-workette-item-hover-hide {
  display: flex;
  align-items: center;
  gap: 2px;
  // flex-wrap: wrap;
}
