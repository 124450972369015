@import "./colors";

.progress-bar-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .progress {
    border-radius: 14px;
    height: 20px;
    flex: 1;
    background-color: $primary-lightgray;

    .success {
      background: $gradient-progress-bar-completed;
    }
  }
}
