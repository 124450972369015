$primary-color: #676cdb;
$primary-active: #5257af;

$primary-dark: #01030a;
$primary-gray: #f8f8f8;
$primary-lightgray: #e9ecef;
$primary-gold: #fcd53fbf;

$bg-dark: #e0e2e9;

$text-white: #ffffff;

$gradient-primary: linear-gradient(116.22deg, #9599fa 35.34%, #676cdb 100%);
$gradient-trophy-wall: linear-gradient(
  116.61deg,
  #b3fce2 11.5%,
  #a1e0fc 32.8%,
  #ebc7fc 68.81%,
  #f6e5fe 86.52%
);
$gradient-progress-bar-completed: linear-gradient(90deg, #6fda94 -5.45%, #4bb46f 100%);

$success-dark: #3b9b5c;

$side-nav-bg-color: $primary-gray;
$side-nav-hover-color: $primary-color;

$workette-done: #dbf1e3;
$workette-canceled: #edeef2;
$workette-link: #deefff;
$workette-note: $workette-link;
$workette-in-progress: #e7e7ff;

$workette-hover-color: $primary-color;

$due-date-color: #ff7a00;

$highlight-most-proud: #464df3;
$highlight-happiest: $primary-color;
$highlight-most-work: #a14fe1;
