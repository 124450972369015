@import "../../../styles/colors";

.frozen-bar {
  color: white;
  background-color: $primary-color;
  text-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: 8rem 1fr;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;

  &.right-button-visible {
    grid-template-columns: 8rem 1fr 9rem;
  }

  &.inactive {
    background-color: $primary-color;

    .unfreeze-button {
      background-color: $primary-active;
    }
  }

  .title {
    padding: 5px;
  }

  > span:not(.title) {
    padding: 2px 6px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    height: 31px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $primary-active;
    }
  }

  .unfreeze-button {
    width: 8rem;
    justify-content: center;
    border-right: 1px solid white;
  }

  .latest-day-button {
    gap: 4px;
    border-left: 1px solid white;
  }
}
