@import "../styles/colors";

.note-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 5px;

  >header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    padding: 0 0.5rem 0.25rem;

    .label {
      align-self: flex-end;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 90%;

      &.saved {
        color: green;
      }

      &.saving {
        color: grey;
      }
    }

    .full-note-button {
      cursor: pointer;
    }
  }

  .note-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .workette-note-form {
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;

    .workette-note-editor {
      display: none;
    }

    .quill {
      word-break: break-word;
      width: 100%;
      background-color: $primary-gray;
      border-radius: 5px;

      .ql-toolbar {
        background-color: white;

        display: flex;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        flex-wrap: wrap;
        justify-content: center;

        .ql-formats {
          margin-right: 0;
        }
      }

      .ql-container {
        .ql-editor {
          min-height: 12rem;
        }
      }
    }
  }

  .link-note .form-input {
    width: 100%;
    height: 100%;
  }
}

.ql-bubble .ql-tooltip {
  z-index: 1;
}

.ql-bubble .ql-out-bottom,
.ql-bubble .ql-out-top {
  visibility: hidden;
}

.ql-bubble .ql-tooltip.ql-editing .ql-tooltip-editor {
  display: flex !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a::before {
  max-width: 300px;
  min-width: 200px;
  word-break: break-all;
  white-space: pre-wrap;
  text-align: center;
}

.tox-tinymce {
  .tox-editor-header {
    padding: 0.25rem !important;
  }

  .tox-toolbar {
    gap: 0 0.5rem;
  }

  .tox-toolbar__primary,
  .tox-toolbar {
    gap: 0 0.5rem !important;

    .tox-toolbar__group {
      padding: 0 !important;
      // gap: 0.25rem;
    }
  }

  .tox-statusbar__branding {
    display: none !important;
  }

  button[aria-label="Font sizes"] {
    width: 5rem;
  }
}