@import '../../styles/colors';

.subscription-lock-wrapper {
    min-height: 100vh;
    position: absolute;
    inset: 0;
    z-index: 9999;
    background-color: #ffffff80;
    display: grid;
    place-content: center;

    .subscription-lock-container {
        max-width: 80ch;
        overflow: hidden;
        border: 1px solid lightgray;
        border-radius: 0.5rem;
        background: white;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
    }

    header {
        background-color: #15162c;
        color: white;
        text-align: center;
        padding: 0.5rem;
        font-weight: 600;
        min-height: 37px;
    }

    main {
        background-color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .plan {
            border: 1px solid $primary-color;
            border-radius: 0.75rem;
            position: relative;
            padding: 1rem;
            background-color: #e3e4f8;

            .description {
                flex: 1 1;
                line-height: 1;
                grid-gap: 0.5rem;
                gap: 0.5rem;

                >span {
                    font-weight: 500;
                    color: rgba(1, 3, 10, 0.75);
                    font-size: 0.75rem;
                }
            }
        }

        .plan-side {
            display: flex;
            align-items: center;

            .extra {
                background-color: #676cdb;
                padding: 0.25rem 0.5rem;
                color: white;
                border-radius: 0.25rem;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 0.75rem;
            }
        }


    }

    button {
        border: none;
        background-color: $primary-color;
        padding: 0.5rem;
        border-radius: 1rem;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        word-spacing: 2px;

        &:disabled {
            background-color: lighten($color: $primary-color, $amount: 12%);
        }
    }
}