.with-text.focus-btn {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.25);
  transition: 0.3s box-shadow, 0.3s opacity ease-in-out;
  padding: 3px 5px;
  display: flex;
  gap: 5px;
  border-radius: 3px;
  opacity: 0.75;

  > svg {
    width: 1em !important;
  }

  // span {
  //   color: rgba(0, 0, 0, 0.25);
  // }
  &.active {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 3px 5px #00000080;
    opacity: 1;
  }
}
.wkt-item-single-compact {
  .focus-btn {
    margin: 3px 0;
  }
}
