@import "../../styles/colors";

.recommendations-view {
  padding: 16px;
  background-color: $primary-gray;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  img {
    height: 1.5rem;
  }

  .info-tooltip {
    margin-right: 4px;
  }

  .dismiss-btn {
    cursor: pointer;
    margin-right: 4px;
  }

  .main-label {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .caption-row {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .all-done {
    // font-size: 20px;
    text-align: center;
  }
  .workette-container {
    padding-right: 0px;
  }
  .progress-bar-row {
    margin-bottom: 8px;
  }

  .focus-recommendation-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
