.snooze-wrapper {
  .snooze-text {
    cursor: pointer;
    transition: background-color 100ms ease-in-out;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1;

    &:hover {
      background-color: lightgray;
    }
  }
}

.snooze-popover {
  .snoozing-menu {
    display: flex;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      li {
        font-size: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: #d3d3d380;
        }

        &[data-type="has-divider"] {
          border-top: 1px solid #cccccc;
        }

        &:last-child {
          position: relative;
          border-top: 1px solid #cccccc;

          > label {
            cursor: pointer;
            margin-bottom: 0;
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;

            > input[type="checkbox"] {
              zoom: 1.5;
              cursor: pointer;
            }
          }

          .help-tooltip {
            right: 0.5rem;
            top: 4px;
          }
        }
      }
    }
  }
}

.snooze-custom-date-modal {
  z-index: 9999 !important;
}
