.recommendation {
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    height: 1.5rem;
  }
}
