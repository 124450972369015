@import "../../styles/mixins";
@import "../../styles/colors";

.myca-loader {
  @include grid-center;

  &[data-fullscreen="true"] {
    @include whole-screen;
  }

  &[data-fullscreen="false"] {
    @include whole-parent;
  }

  .loader {
    position: relative;
    height: 2rem;
    width: 6rem;
    display: flex;
    background-color: white;
    font-size: 2rem;
    overflow: hidden;

    .myca-progress {
      width: 0;
      top: 0;
      left: 0;
      position: absolute;
      background: linear-gradient(to right, $primary-color 0%, $primary-color 100%);
      background-clip: text;
      color: transparent;
      animation: progressAnimation 2s linear infinite;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 4px;
      display: flex;
    }

    .text {
      top: 0;
      left: 0;
      position: absolute;
      transform: translate(-100%, -100%);
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 4px;
    }
  }
}
