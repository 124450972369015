.billing-issue-wrapper {
    .issue-description-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        svg {
            font-size: 3rem;
            color: orange;
            margin-block: 1rem;
        }

        strong {
            p:first-child {
                margin-bottom: 1rem;
            }
        }


    }

    .loading-wrapper {
        display: grid;
        place-content: center;
        min-height: 40vh;
    }
}