@import "../../../../styles/mixins";
@import "../../../../styles/colors";
@import "../../../../styles/utils";

.associate-recommendation-button {
  @include button;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  background-color: $primary-gold;

  > span {
    display: none;
    line-height: 1;

    @include larger-than-phone {
      display: inline-block;
    }
  }

  > img {
    width: px-to-rem(14px);
    aspect-ratio: 1/1;
  }
}
