@import "../../styles/mixins";
@import "../../styles/colors";

.settings-wrapper {
  display: grid;
  font-size: 14px;
  overflow: hidden auto;
  background-color: white;

  @include larger-than-phone {
    min-height: calc(100vh - 48px);
  }

  @include desktop {
    min-height: 100vh;
  }

  .nav-link {
    color: #000;
    padding-right: 50px;
    border-left: transparent 5px solid;
  }
  .nav-link:hover,
  .nav-link.active {
    background-color: $primary-gray;
    color: #000 !important;
    border-left: blue 5px solid;
  }

  .text-input {
    border: 1px #d8d8d8 solid;
    border-radius: 5px;
    width: 250px;
  }

  .settings-nav {
    box-shadow: 0px 2px 5px -1px lightgrey;
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
    height: max-content;

    @include larger-than-phone {
      padding-block: 0;
      box-shadow: none;
      border-right: 1px solid lightgrey;
      padding-right: 0;
      min-height: 100%;
      max-width: 12rem;
    }

    h5 {
      padding-left: 1rem;

      @include larger-than-phone {
        padding-left: 0;
      }
    }
  }
}
