@import "../../styles/mixins";

.app-setting-img-select {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include larger-than-phone {
    flex-direction: row;
  }

  .select {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-self: flex-start;
    gap: 0 0.5rem;
    flex-wrap: wrap;

    @include larger-than-phone {
      width: 7rem;
    }
  }

  .app-settings-img {
    width: 350px;
    border: 2px solid black;
  }
}
