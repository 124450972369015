@import "../../../../styles/colors";
@import "../../../../styles/typography";

.clear-link {
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.ritual-button {
  .ritual-summary {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease-in-out;
    line-height: 1;
    display: inline-flex;


    &:hover {
      background-color: lightgray;
    }
  }
}


.recurring-button {
  font-size: 14px;
  background-color: unset !important;
  color: rgba(0, 0, 0, 0.5) !important;
  border-color: unset !important;
  border: unset !important;
  padding: 0 0 0 0 !important;
  display: flex;

  &:focus {
    box-shadow: none;
  }

  &:after {
    display: none !important;
  }
}

.recurring-popover {
  .recurring-menu {
    display: flex;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      li {
        font-size: 1rem;
        padding: 0.5rem;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:not(:last-child):hover {
          background-color: #d3d3d380;
        }

        &:last-child {
          position: relative;
          cursor: default;
          border-top: 1px solid #cccccc;

          >label {
            cursor: pointer;
            margin-bottom: 0;
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;

            >input[type="checkbox"] {
              zoom: 1.5;
              cursor: pointer;
            }
          }

          .help-tooltip {
            right: 0.5rem;
            top: 4px;
          }
        }
      }
    }
  }
}