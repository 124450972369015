@import "../../../styles/colors";

.filtered-day-view {
  padding: 1rem;
  background-color: $primary-gray;
  display: flex;
  flex-direction: column;
  width: 100%;

  .top-row {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin: 0;

    >div {
      flex: 1 !important;
      padding: 0;

      &:first-child {
        display: flex;
        align-items: center;
        flex: 2 !important;
      }
    }

    svg {
      margin-right: 5px;
    }

    .main-label {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .caption-row {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 5px;
  }

  .all-done {
    // font-size: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    padding-block: 1rem;
  }

  .workette-container {
    padding-right: 0px;
  }

  .progress-bar-row {
    margin-bottom: 8px;
  }

  .workette-skeletons {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .single-workette-item {
      margin: 0;
    }
  }

  .workset-add-form {
    padding-bottom: 1rem;

    &.visible {
      padding-bottom: 0;
    }
  }

  .image-container {
    .image-container {
      background-color: white;
      border-radius: 0.5rem;
      width: 100%;
      display: grid;
      place-content: center;

      picture {
        img {
          object-fit: contain;
        }
      }
    }
  }
}

.perform-right-column-container {
  .filtered-day-view {
    background-color: #ffffff;
  }
}