@import "./utils";
@import "./mixins";

$page-mode: comfortable;

$font-xxl: px-to-rem(24px);
$font-xl: px-to-rem(20px);
$font-lg: px-to-rem(18px);
$font-md: px-to-rem(16px);
$font-sm: px-to-rem(14px);
$font-xs: px-to-rem(12px);

$base-font-size: $font-sm;

h1 {
  @include headings-1;
}

h2 {
  @include headings-2;
}

h3 {
  @include headings-3;
}

h4 {
  @include headings-4;
}

h5 {
  @include headings-5;
}
