.link-preview-img {
  display: flex;
  gap: 1rem;
  max-width: 50em;
  padding: 0.5rem;

  .desc {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > span {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  > img {
    max-width: 50%;
  }

  .footer-text {
    display: flex;
    flex-direction: column;
  }
}
