@import "../../../styles/colors";

.date {
  border-radius: 20px;
  padding: 3px 8px;
  margin: 0px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  min-width: 50px;
  text-align: center;
  cursor: pointer;

  &.selected {
    border-color: #0e3ff2;
    background: #0e3ff2;
    color: white;
  }

  &.not-selected {
    border-color: $primary-gray;
    background: $primary-gray;
    color: grey;
  }
}
