@import "../../styles/colors";

.recommendation-wkt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #fff;
  align-items: center;
  gap: 4px;
  padding: 4px;

  .button {
    padding: 4px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &.accept {
      background-color: #00e880;
      &:hover {
        background-color: #00a040;
      }
    }

    &.reject {
      background-color: #fb7777;
      &:hover {
        background-color: #c03030;
      }
    }
  }

  .name {
    padding: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .parent-info {
    background: $primary-gray;
    align-items: center;
    text-align: center;
    margin: 4px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 7px;
  }
}
