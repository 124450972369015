@import "../../styles/mixins";

.improve-section {
  padding: 0.75rem 1rem;
  min-height: 100vh;
  position: relative;

  @include desktop {
    padding: 1rem;
  }

  .scroll-to-top {
    position: fixed;
    bottom: 6rem;
    right: 20px;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #464ff5;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s box-shadow ease-in-out;
    will-change: box-shadow;

    &:hover {
      box-shadow: 0 0 5px 2px grey;
    }
  }

  .tab-pane {
    min-height: 90vh;

    &.active {
      min-height: auto;
    }
  }
}
