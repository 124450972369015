@import "../../styles/utils";

.settings-wrapper {
  .form-input {
    max-width: px-to-rem(250px);

    svg {
      cursor: pointer;
    }
  }
}
