@import '../../../styles/colors';
@import '../../../styles/mixins';

.perform-right-column-container {
    background-color: $primary-gray;

    position: relative;
    display: none;

    @include desktop {
        display: flex;
    }

    .right-side-bar {
        row-gap: 0.75rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: 100%;
        width: 350px;
        height: 100vh;
        overflow: hidden auto;

        &[data-hidden="true"] {
            display: none;
        }
    }

    .tabs-container {
        position: absolute;
        transform: translateX(-100%);
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .toggle-button {
            display: grid;
            place-content: center;
            color: gray;
            margin-block: 0.5rem;
        }

        svg {
            font-size: 14px;
            height: 14px;
            width: 14px;
        }

        .tabs {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            cursor: pointer;
            align-items: center;

            .tab {
                color: gray;
                transition: background-color 300ms, color 300ms ease-in-out;
                padding: 0.25rem 0.5rem;
                line-height: 1;

                &.active,
                &:hover {
                    color: black;
                    background-color: #f8f8f8;
                }
            }
        }
    }
}