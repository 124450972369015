.title-container{
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:flex-end;
    width:100%;

    .title{
        flex-grow:1;
        text-align:left;
        font-weight:bold;
    }

    .title-control{
        text-align:center;
        padding-left:10px;
        color:blue;
        cursor:pointer
    }
}



.iframe-modal{
    min-width:60vw !important;
    min-height:80vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .modal-content{
        height:80vh;
        width:100%;
    }
}

