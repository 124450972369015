@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap");

@import "./colors";
@import "./utils";
@import "./spacing";
@import "./typography";
@import "./buttons";
@import "./forms";
@import "./cards";
@import "./progress-bar";
@import "./tabs";

:root {
  --primary-spacing: 1rem;
  --base-font-size: 14px;
  --page-mode: comfortable;
}

@mixin set-root-variable($page-mode) {
  @if $page-mode == comfortable {
    --base-font-size: #{$base-font-size};
    --primary-spacing: #{$comfortable-size};
  } @else if $page-mode == compact {
    --base-font-size: #{$font-xs};
    --primary-spacing: #{$compact-size};
  } @else {
    @error "Invalid mode";
  }
  --page-mode: #{$page-mode};
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  font-family: Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden auto;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.pointer-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes progressAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.full-screen-center {
  @include whole-screen;
  @include grid-center;
}

.full-parent-center {
  @include whole-parent;
  @include grid-center;
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.comfortable-mode {
  $page-mode: comfortable;
  @include set-root-variable($page-mode);

  font-size: var(--base-font-size);
}

.compact-mode {
  $page-mode: compact;
  @include set-root-variable($page-mode);

  font-size: var(--base-font-size);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.bg-success {
  background-color: $success-dark !important;
}

.bg-warning {
  background-color: $primary-gold !important;
}

.bg-info {
  background-color: $primary-color !important;
}
