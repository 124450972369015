@import "../../styles/mixins";

.bg {
  background: #78bbd0 URL("activation_bg.webp") no-repeat;
  background-size: cover;
  object-fit: contain;
  object-position: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-inline: 1rem;
  margin: 0;

  .activation-container {
    padding: 1rem;
    color: black;
    width: 500px;
    height: 400px;
    background-color: #ffffffd9;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 3px 3px green;

    .spinner-grow {
      top: 40%;
      position: relative;
    }

    .circle {
      font-size: 2rem;
      border-radius: 50%;
      width: 4rem;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      color: white;
      justify-content: center;

      @include desktop {
        font-size: 3rem;
        width: 6rem;
      }

      &.valid {
        background: lightgreen;
      }

      &.invalid {
        background: #ff000099;
      }
    }

    strong {
      font-size: 1.5rem;
    }

    p {
      text-align: center;
      font-weight: 600;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      margin-top: 3rem;
      font-weight: 600;
    }
  }
}
