@import "../../../../styles/mixins";
@import "../../../../styles/colors";

.small-input {
  width: 50px !important;
  text-align: center;
}

.ritual-popover {
  max-width: none !important;
}

.ritual-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ritual-row {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 0.5rem;

    > .col {
      padding: 0;

      &.min-width-auto {
        min-width: auto !important;
      }
    }

    &.ritual-end-row {
      align-items: flex-start;
      flex-direction: column;
    }

    .row-label {
      min-width: 7rem;
      font-weight: 600;
    }

    .ritual-end-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;
      width: 100%;
      max-width: 260px;

      > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        label {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          min-width: 7rem;

          &.ritual-occurence-label {
            background-color: $primary-gray;
            padding: 0.25rem;
            color: gray;
          }
        }

        #ritual-occurrences {
          @include thin-border-lightgray;
          outline: none;
          width: 8ch;
        }
      }
    }
  }

  .ritual-weekdays {
    padding: 4px;
  }

  select,
  input[type="number"],
  input[type="date"] {
    @include thin-border-lightgray;
    outline: none;
    padding: 0.25rem;
    border-radius: 0.25rem;
  }
}

.ritual-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid grey;

  .footer-button {
    padding: 8px;
  }
}

.recurring-popover {
  max-width: max-content !important;
}
