@import "../../../styles/colors";
@import "../../../styles/mixins";

.onboarding-main-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  background-color: #0906064d;

  &.no-overlay {
    width: 0px;
    height: 0px;
    inset: unset;
    top: 0;
    left: 0;
  }

  .plan-onboarding-container {
    display: flex;
  }

  >section {
    padding: 1rem;
    gap: 1rem;
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    position: absolute;
    top: var(--posTop, 0);
    left: var(--posLeft, 0);
    transform: var(--posTranslate);
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 0px 4px);

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-top: 0.75rem solid #f5f5f5;
      position: absolute;
      inset: var(--arrowInset);
      transform: var(--arrowTranslate) rotate(var(--arrowRotate, 0deg));
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      font-weight: 500;
    }

    main {
      picture img {
        max-width: 100%;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    gap: 1rem;
  }

  .next-button,
  .footer-btn,
  .back-button {
    @include button;
  }

  .next-button,
  .footer-btn {
    @include primary-button;
  }

  .back-button {
    @include secondary-button;
    background-color: lightgray;

    &:hover {
      background-color: gray;
    }
  }
}