.myca-tab {
  .tab-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include larger-than-phone {
      padding-right: 1rem;
    }
  }

  .nav-link {
    color: #000;
    border-bottom: 1px solid #dee2e6;
    font-weight: 500;

    &.active,
    &.show .nav-link,
    &:hover,
    &:focus {
      border: 1px solid transparent;
      border-bottom: 2px solid $primary-color;
      color: $primary-color;
    }
  }

  .tab-pane {
    background-color: #ffffff;
  }
}