@import "./styles/spacing";
@import "./styles/mixins";
@import "./styles/colors";

@keyframes show-banner {
  from {
    top: -100%;
  }

  to {
    top: 0;
  }
}

@keyframes show-side-bar {
  from {
    transform: translateX(calc(-100% - 5px));
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes hide-side-bar {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(calc(-100% - 5px));
  }
}

@keyframes menu-to-left {
  from {
    transform: translateX(calc(100% + 5px));
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes menu-to-right {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(calc(100% + 5px));
  }
}

.page-loader {
  display: grid;
  height: 100vh;
  width: 100%;
  place-content: center;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .app-banner {
    position: fixed;
    top: -100%;
    left: 0;
    padding: 1rem;
    font-size: 1rem;
    min-height: 3rem;
    z-index: 9999999999999;
    width: 100%;
    background: linear-gradient(90deg, #2496ff 4.49%, #2348c4 91.67%);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    animation: show-banner 1s ease-out 0.3s forwards;

    >a {
      color: white;
      text-decoration: none;
      padding: 0.25rem 0.5rem;
      border: 2px solid white;
      border-radius: 5px;
      transition: 0.3s background-color ease-in-out;

      &:hover {
        background-color: #ffffff23;
      }
    }
  }
}

.pointer-none {
  pointer-events: none !important;

  &::selection {
    background-color: none;
  }
}

.App {
  text-align: center;
  font-family: Manrope;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: var(--light-link);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.day-app-row {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content 1fr;

  .api-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2rem;
  }
}

.day-app-main-column {
  background-color: #ffffff;
  position: relative;

  .app-header {
    position: sticky;
    width: 100%;
    inset: 0;
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;
    padding: 0.5rem;
    z-index: 1;
    background: white;
    box-shadow: 0 0 2px 2px lightgrey;

    @include desktop {
      display: none;
    }

    >* {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu-button {
      color: #3d67f6;
      cursor: pointer;
    }

    .logo-wrapper {
      img {
        width: 3rem;
        aspect-ratio: 1;
      }
    }
  }

  @include desktop {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.day-app-nav-column {
  inset: 0;
  position: fixed;
  z-index: 99;
  display: flex;
  flex-direction: column;
  animation: none;

  .menu-close-button {
    display: none;
  }

  @media (max-width: 63.9em) {
    transform: translateX(calc(-100% - 5px));

    // &.collapsed {
    //   animation: show-side-bar 300ms ease-in-out forwards;
    //   box-shadow: 0 0 3px 3px lightgrey;
    // }

    // &.toggled {
    //   animation: hide-side-bar 300ms ease-in-out forwards;
    // }

    .menu-close-button {
      position: absolute;
      display: flex;
      right: 0;
      padding: 1rem;
      right: 0;
      z-index: 99;
      color: #3d67f6;
      background-color: transparent;
      cursor: pointer;

      @include desktop {
        display: none;
        pointer-events: none;
      }
    }
  }

  @include desktop {
    &.lg {
      transition: width 300ms ease-in-out;
      max-height: 100%;
      display: none;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
      width: $side-nav-width;
      flex: none;
      position: sticky;
      top: 0;
      transform: translateX(0%);

      @include desktop {
        display: flex;
      }

      .menu-button-wrapper {
        display: none;
      }

      &.toggled {
        width: $side-nav-width-toggled;
      }
    }
  }

  .nav-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid rgb(216, 216, 216);
    padding-top: 1rem;

    .onboarding-videos-button {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        cursor: pointer;
      }
    }

    >span {
      overflow: hidden;
      text-align: center;
      padding: 0 0.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.video-wrapper {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  background-color: #0906064d;

  .video-continer {
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 83vh;
    position: relative;
    top: 1rem;

    @media (min-height: 30em) {
      height: 60vh;
    }

    @include desktop {
      width: 70vw;
    }

    .close-btn {
      position: absolute;
      left: 50%;
      transform: translate(-50%, calc(-85% - 0.5rem));
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        color: white;
        filter: drop-shadow(0 0 5px black);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        transition: background-color 300ms ease-in-out;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 2px;

        &:hover {
          background-color: black;
        }
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      flex: 1;
    }
  }

  .footer-text {
    background-color: white;
    padding: 1rem;
    display: flex;
    gap: 1rem;
  }
}

input[type="text"].message-input,
textarea.message-input {
  height: calc(var(--base-font-size) * 3 - 2px);
  resize: none;
  min-height: auto;
  overflow-y: hidden;
}

textarea.name-form {
  height: 4rem !important;
  flex: 1 1;
  line-height: 1.5;
  overflow-y: auto;
}

.rta-wrapper {
  position: relative;
  display: flex;
  gap: 0.5rem;

  .rta {
    width: 100%;
    display: flex;
  }

  >.buttons {
    display: flex;
    gap: 0.25rem;
    background-color: white;
    align-items: center;
    flex: 0;

    >div {
      cursor: pointer;
      font-size: 1rem;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color 0.3s ease-in-out;
      flex: 1;

      >svg {
        position: relative;
        left: 0.75px;
        font-size: 0.875rem;
      }

      &:hover {
        background-color: lightgray;
      }
    }
  }
}

/* RTA
       ========================================================================== */

.rta__autocomplete {
  position: absolute;
  // width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 2;
}

.rta__autocomplete ul {
  list-style: none;
  text-align: left;
  margin-bottom: 0;
  padding-left: 0;
}

.rta__autocomplete li {
  margin-bottom: 5px;
  padding: 3px 10px;
  cursor: pointer;
}

.rta__autocomplete li:hover {
  background-color: skyblue;
}

.bg-gray {
  background-color: $primary-gray;
}

.__floater {
  button {
    &:disabled {
      background-color: #ff004480 !important;
    }
  }
}

.zIndex-1000 {
  z-index: 1000;
}

.animate-div {
  will-change: transform;
}

::-webkit-scrollbar {
  display: none;

  @include desktop {
    display: inline-block;
  }
}