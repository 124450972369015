.daily_note {
  display: flex;
  flex-direction: column;

  > span {
    padding: 5px 0 5px 5px;
  }

  .quill {
    height: auto !important;

    .ql-editor {
      height: 84vh;
    }
  }
}
