@import "../../styles/mixins";
@import "../../styles/colors";

.perform-main-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @include desktop {
    grid-template-columns: 1fr auto;
  }

  .perform-main-view-container {
    overflow: hidden auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    // height: calc(100dvh - 1.5rem);
    // height: calc(100vh - 1.5rem);
    position: relative;
    padding: 0.75rem 1rem;

    .toggle-left-nav-button {
      cursor: pointer;
      color: gray;
      transition: color 300ms ease-in-out;
      display: inline-flex;
      width: max-content;

      &:hover {
        color: black;
      }
    }

    .trophy-toggle {
      display: none;

      @include desktop {
        display: block;
        position: absolute;
        right: 8px;
        z-index: 3;
      }
    }

    .perform-main-container {
      position: relative;
      padding: 0;

      .main-loader {
        display: grid;
        place-content: center;
        height: 100%;
      }

      .tab-content {
        .tab-pane {
          height: 82vh;
          height: 82dvh;
          overflow: hidden auto;
        }
      }
    }

    .custom-certify-day {
      margin: 0;
      display: none;

      @include desktop {
        display: flex;
      }

      .row {
        align-items: center;

        span {
          margin-right: 1rem;
        }
      }
    }
  }

  .twoRowDate {
    display: flex;
    flex-direction: column;

    .date-top-row {
      display: flex;
      font-size: 1.25rem;
      font-weight: 500;
      gap: 0.5rem;
    }

    .date-bottom-row {
      font-weight: 500;
      font-size: 1rem;
    }
  }



  .image-container {
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    display: grid;
    place-content: center;

    img {
      object-fit: contain;
      object-position: center;
      max-width: 100%;
    }

    .primary-button {
      width: fit-content;
      justify-self: center;
      display: flex;
    }
  }

}


@include larger-than-phone {
  ::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(116.61deg,
        #b3fce2 11.5%,
        #a1e0fc 32.8%,
        #ebc7fc 68.81%,
        #f6e5fe 86.52%);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(116.61deg,
        #b3fce2 11.5%,
        #a1e0fc 32.8%,
        #ebc7fc 68.81%,
        #f6e5fe 86.52%);
    border-radius: 10px;
  }
}