.release-modal{
    h3{
        font-size:18px;
    }

    h4{
        font-size:12px;
        color: gray;
        font-style: italic;
    }

    ul{
        font-size:12px;
        tab-size: 4;
    }
}