.filter-container {
  display: flex;
  flex-direction: column;
  width: 12rem;

  ul {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0%;
    
    li {
      list-style: none;
      gap: 1.5rem;
      font-size: 1rem;

      > label {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin: 0%;
        cursor: pointer;  
        text-transform: capitalize;

        &:hover {
          color: rgb(61, 103, 246);
        }

        input[type="checkbox"] {
          zoom: 1.5;
        }
      }
    } 
  }

  .filter-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: 1rem;

    label {
      cursor: pointer;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      color:blue;
    }
  }
}