@import "../../../styles/colors";

@keyframes complete {
  from {
    background-color: lighten($workette-done, 30%);
  }

  to {
    background-color: $workette-done;
  }
}

@keyframes canceled {
  from {
    background-color: lighten($workette-canceled, 30%);
  }

  to {
    background-color: $workette-canceled;
  }
}

@keyframes snoozed {
  from {
    background-color: lighten(#ffffed, 30%);
  }

  to {
    background-color: #ffffed;
  }
}

@keyframes link {
  from {
    background-color: lighten($workette-link, 30%);
  }

  to {
    background-color: $workette-link;
  }
}

@keyframes note {
  from {
    background-color: lighten($workette-note, 30%);
  }

  to {
    background-color: $workette-note;
  }
}

@keyframes run {
  from {
    background-color: lighten($workette-in-progress, 30%);
  }

  to {
    background-color: $workette-in-progress;
  }
}

@keyframes run-beacon {
  0% {
    fill: #bfbfbf;
  }

  50% {
    fill: rgb(0, 0, 0);
  }

  100% {
    fill: #bfbfbf;
  }
}

.d-flex {
  position: relative;
  border-left: 2px solid var(--sideLineColor);
  padding-top: var(--itemGap);
}

.full-day-skeleton,
.workette-skeletons {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .single-workette-item {
    margin: 0;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 0.5rem;

    >span:nth-child(2) {
      flex: 1;
    }
  }
}

.workette-skeletons {
  padding: 0;
}

.workette-add-form {
  position: relative;

  >div {
    padding-top: 0.25rem;
    padding-left: 0.75rem;
  }
}

.perform-right-column-container {
  .single-workette-item {
    background: $primary-gray;
  }

  .single-workette-item-hover-hide {
    display: none !important;
  }

  .single-workette-item-hover-show {
    display: flex;
  }
}

.link-indicator {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  border-radius: 5px 0px 5px 0px;
}

.dropdown-menu {
  min-width: 100% !important;

  .single-workette-item-hover-hide {
    flex-direction: column;

    span {
      padding: 0 0.5rem !important;
    }
  }

  .group-buttons-dropdown {
    display: flex;
    flex-direction: column;
  }

  .dd-item {
    padding: 0 0.5rem;
    pointer-events: none;

    >span {
      pointer-events: auto !important;
    }
  }

  .hover-buttons {
    display: none;

    @media (hover: none) {
      display: flex;
      flex-direction: column;
      padding-left: 0 !important;

      &::before {
        display: none !important;
      }
    }
  }
}

.workset-add-form {
  display: flex;
  gap: 0.25rem;
  // align-items: center;
  padding-left: 12px;
  padding-bottom: 6px;
  // margin: 2px 0 4px;
  position: relative;

  >div {
    align-self: center;
    position: relative;
    top: 4px;
  }

  >span {
    &:last-child {
      flex: 1;
    }
  }

  .wkt-buttons {
    position: relative;
    top: 4px;
    margin-bottom: 0;
  }

  &.visible {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0.5rem;
  }

  strong {
    font-size: 0.875rem;
    cursor: default;
  }

  .workset-add-form-accordion {
    width: 100%;
  }
}

.workette {
  padding-top: max(calc((var(--primary-spacing) / 2) - 2px), 4px);

  &[data-extra-gap="true"] {
    padding-top: max(calc((var(--primary-spacing) / 2) + 3px), 4px);
  }

  &[data-mode="compact"] {
    .single-workette-item {
      padding-block: 0;
    }
  }

  .single-workette-item {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    background: #ffffff;
    border-radius: 7px;
    padding: calc(var(--primary-spacing) / 2) calc(var(--primary-spacing) / 4);
    cursor: pointer;
    gap: 3px;
    will-change: background-color;
    transition: background-color, box-shadow 300ms ease-in-out;
    color: #212529;

    &.done {
      animation: complete 100ms ease-in-out forwards;
    }

    &.canceled {
      animation: canceled 100ms ease-in-out forwards;
    }

    &.snoozed {
      animation: snoozed 100ms ease-in-out forwards;
    }

    &.link {
      animation: link 100ms ease-in-out forwards;
    }

    &.note {
      animation: note 100ms ease-in-out forwards;
    }

    &.running {
      animation: run 100ms ease-in-out forwards;
    }

    &.wkt-item-single-compact {
      // padding: 1px 4px !important;
    }

    &:hover,
    &[data-dragover="true"] {
      box-shadow: 2px 2px 8px $workette-hover-color;
    }

    &.onboarding-drill-item .drill-btn {
      .css-1p4pgss-BaseToolTop {
        z-index: 9999;
      }

      svg path {
        animation: run-beacon 1s ease-in-out infinite;
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;

      >.col {
        display: flex !important;
        align-items: center;
        padding: 0;
      }
    }

    .left {
      gap: 3px;
      padding-right: 0.5rem;
      width: 100%;
      position: relative;

      .left-icons {
        max-width: 50px !important;
        flex: 0;
      }

      .workette-title {
        gap: 5px;
        position: relative;
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;

        .link-preview-tooltip {
          // text-overflow: ellipsis;
          // overflow: hidden;

          // .title {
          //   white-space: nowrap;
          // }
          .title-wrapper {
            overflow: hidden;
            text-overflow: ellipsis;

            .title {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }

        >.title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          min-width: 3ch;
        }

        .link-preview-img {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          >span {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .footer-text {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .parent-info-wrapper {
        display: flex;
        align-items: center;

        .parent-info {
          background: #f8f8f8cc;
          align-items: center;
          text-align: center;
          padding: if(var(--page-mode)==comfortable, 0.25rem, 3px 0.25rem);
          gap: 0.25rem;
          border-radius: 0.25rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-grid;
          grid-template-columns: max-content 1fr;
          min-width: 5ch;

          .square {
            height: 12px;
            width: 12px;
          }

          .title {
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1;
          }
        }
      }

      .hover-buttons {
        display: none;
        flex: 1;
        z-index: 1;
        background-color: transparent;

        @media (min-width: 50em) and (hover: hover) {
          display: flex;
        }
      }

      .workette-parent {
        width: 30%;
        flex: 0;
      }
    }

    .right {
      gap: 5px;
      justify-content: flex-end;
      width: min-content;

      .created-on {
        color: green;
      }

      .due-date {
        justify-content: flex-end;
        color: $due-date-color;
        width: max-content;

        >span {
          padding-right: 0 !important;
        }
      }

      .children-count {
        max-width: 50px !important;
        justify-content: flex-end;
        flex: 0;
      }

      .right-icons {
        justify-content: flex-end;
        align-self: flex-end;
        flex: 0;

        .hover-buttons {
          display: none;

          @media (hover: none) {
            display: flex;

            &::before {
              display: block;
              border-left: 2px solid lightgray;
              content: "";
              padding-left: 2px;
            }

            @media (min-width: 60em) and (hover: none) {
              display: flex;
              padding-left: 2px;

              &::before {
                content: "";
                display: block;
                border-left: 1px solid lightgray;
                padding-right: 2px;
              }
            }
          }
        }

        .group-buttons {
          display: flex;
          align-items: center;
          gap: 1px;
        }
      }
    }
  }
}