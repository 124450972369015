@import "../../../styles/colors";
@import "../../../styles/typography";

$darkBlue: #202531;
$black: #202029;

.stripe-loading {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: #80808080;
  z-index: 999;

  >div {
    color: black;
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 5rem;
  }
}

.subscription-container {
  max-width: 1116px;
  min-height: calc(100vh - 2rem);

  .pricing-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem 2rem;

    @include desktop {
      flex-direction: row;
    }

    // .plans {
    //   flex: 1 1 40%;
    //   gap: 1rem;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   width: 100%;

    //   @include desktop {
    //     max-width: 30vw;
    //   }

    //   .plan {
    //     @include thin-border-lightgray;
    //     border-radius: 4px;
    //     padding: 1.25rem;
    //     // max-width: 25rem;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 1rem;
    //     transition: box-shadow 300ms ease-in-out;
    //     color: $primary-dark;
    //     position: relative;

    //     >h3 {
    //       display: grid;
    //       grid-template-columns: max-content max-content;
    //       justify-content: space-between;
    //       gap: 1rem;

    //       .best-value {
    //         padding: 0.25rem 0.5rem;
    //         font-weight: 600;
    //         position: absolute;
    //         right: 0;
    //         top: 0;
    //         color: white;
    //         background-color: red;
    //         border-bottom-left-radius: 0.25rem;
    //         border-top-right-radius: 0.25rem;
    //         font-size: $font-xs;
    //         line-height: 1.25;
    //       }
    //     }

    //     &.active {
    //       border: 2px solid $primary-color;

    //       .price {
    //         span {
    //           font-weight: bold;
    //           color: #85888e !important;
    //         }
    //       }
    //     }

    //     .btn-plan {
    //       transition: all 300ms ease-in-out;
    //       padding: 0.5rem 0.75rem;
    //       border-radius: 0.25rem;
    //       font-weight: 600;
    //       border: 1px solid $primary-color;
    //       color: white;
    //       background-color: $primary-color;

    //       &:hover {
    //         box-shadow: 0 5px 5px -5px $darkBlue;
    //         transform: translateY(-2px);
    //       }

    //       &.cancel-plan {
    //         background-color: $primary-color;
    //         color: white;
    //       }
    //     }

    //     .price-wrapper {
    //       flex: 1;
    //       display: grid;
    //       grid-template-columns: max-content auto;
    //       gap: 1rem;
    //       align-items: center;
    //       justify-content: space-between;

    //       .price {
    //         display: flex;
    //         flex-direction: column;

    //         h1 {
    //           color: $primary-dark;
    //           font-size: 2rem;
    //         }

    //         >span {
    //           font-weight: 600;
    //           color: gray;
    //         }
    //       }

    //       .extra {
    //         font-weight: 600;
    //         display: flex;
    //         flex-direction: column;
    //         line-height: 1.2;

    //         strong {
    //           font-size: $font-md;
    //         }

    //         >span {
    //           color: gray;
    //         }
    //       }
    //     }
    //   }
    // }

    // .features {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 1rem;

    //   .feature {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 0.5rem;

    //     ul {
    //       margin-bottom: 0;
    //       display: flex;
    //       flex-direction: column;
    //       gap: 0.5rem;

    //       li {
    //         display: flex;
    //         align-items: center;
    //         gap: 0.25rem;

    //         input[type="checkbox"] {
    //           appearance: none;
    //           outline: 0;
    //           background: white;
    //           height: 1.2rem;
    //           width: 1.2rem;
    //           border-radius: 100%;
    //           position: relative;

    //           &::after {
    //             content: " ";
    //             position: absolute;
    //             left: 36%;
    //             top: 15%;
    //             width: 30%;
    //             height: 55%;
    //             border: solid #fff;
    //             border-width: 0 2px 2px 0;
    //             transform: rotate(50deg);
    //             display: none;
    //           }

    //           &:checked {
    //             background-color: $primary-color;

    //             &:after {
    //               display: block;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    .plans-wrapper {
      display: flex;
      flex-direction: column;
      border: 1px solid lightgray;
      border-radius: 0.75rem;
      overflow: hidden;
      position: relative;

      @include desktop {
        width: 410px;
      }

      >header {
        background-color: #15162c;
        color: white;
        text-align: center;
        padding: 0.5rem;
        font-weight: 600;
        min-height: 37px;
      }

      >main {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        .price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          color: $primary-dark;

          strong {
            font-size: 3rem;
          }
        }

        .plans {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .plan {
          padding: 1rem;
          border-radius: 0.75rem;
          background-color: lighten($color: $primary-color, $amount: 30%);
          cursor: pointer;
          border: 1px solid white;
          transition: border-color 300ms ease-in-out;

          &.active {
            border: 1px solid $primary-color;
          }

          .description {
            display: flex;
            flex-direction: column;
            flex: 1;
            line-height: 1;
            gap: 0.5rem;

            >span {
              font-weight: 500;
              color: color-opacity($primary-dark, 0.75);
              font-size: $font-xs;
            }
          }

          aside {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .extra {
              background-color: $primary-color;
              padding: 0.25rem 0.5rem;
              color: white;
              border-radius: 0.25rem;
              text-transform: uppercase;
              font-weight: 600;
              font-size: $font-xs;

              &.current {
                background-color: $primary-color;
              }
            }
          }
        }

        button {
          border: none;
          background-color: $primary-color;
          padding: 0.5rem;
          border-radius: 1rem;
          color: white;
          font-weight: 500;
          text-transform: uppercase;
          word-spacing: 2px;

          &:disabled {
            background-color: lighten($color: $primary-color, $amount: 12%);
          }
        }
      }

      >footer {
        padding: 0 1rem 0.5rem 1rem;
        font-size: $font-xs;
        text-align: center;

        span {
          color: $primary-color;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .payment-methods {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

    .payment-method {
      border: 1px solid #33333b;
      border-radius: 0.25rem;
      padding: 1rem 1rem 0.25rem 1rem;
      transition: all 300ms ease-in-out;
      min-height: 119px;
      background-color: $black;
      color: white;
      position: relative;

      &.default {
        border: 3px solid $primary-color;
      }

      .card-type {
        text-transform: capitalize;
      }

      .card-number {
        span {
          line-height: 1;
        }
      }

      .payment-method-footer {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        align-items: center;

        svg {
          color: white;
        }

        .set-default-btn {
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
          transition: border 300ms ease-in-out;
          border: 1px solid #202029;

          &:hover {
            border: 1px solid $primary-color;
          }
        }
      }
    }

    .add-payment-method {
      border: 2px solid #2a2a36;
      border-radius: 4px;
      padding: 1rem;
      transition: all 300ms ease-in-out;
      background-color: #2a2a36;
      color: white;
      display: grid;
      place-content: center;
      cursor: pointer;
      min-height: 119px;

      &:hover {
        background-color: $darkBlue;
      }

      .add-btn {
        width: 2rem;
        height: 2rem;
        display: grid;
        place-content: center;
        padding: 1rem;
        background-color: #3e3e53;
        border-radius: 100vw;
      }
    }

    .fa-btn {
      &:hover {
        background-color: #6a77f0;

        svg>path {
          fill: white !important;
        }
      }
    }
  }

  .check {
    position: absolute;
    right: -0.6rem;
    top: -0.6rem;
    background-color: $primary-color;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100vw;
    display: grid;
    place-content: center;
  }

  .card-logo {
    align-self: center;
    width: 48px;
    min-height: 30px;
    background-color: white;

    img {
      width: 100%;
    }
  }

  .invoice-status {
    display: block;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 100vw;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &[data-status="paid"] {
      background-color: green;
    }
  }
}