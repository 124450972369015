@import "../../../styles/colors";
@import "../../../styles/typography";

.full-day-container {
  position: relative;
  padding: 16px;
  background-color: $primary-gray;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 0;
  max-width: 100% !important;

  .full-day-row {
    display: flex;
    height: 30px;
    align-items: center;

    >.col-sm-auto {
      font-weight: 700;

      >span {
        padding-right: 10px;
      }
    }
  }

  .ReactCollapse--collapse {
    .row {
      margin: 0;

      .col {
        padding: 0;
      }
    }
  }

  .no-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    >img {
      max-width: 100%;
      max-height: 450px;
    }

    .plan-add-button {
      margin: 0;
    }
  }

  .full-day-row {
    margin-bottom: 10px;
    align-items: center;

    >.col-sm-auto {
      font-weight: 700;

      >span {
        padding-right: 10px;
      }
    }
  }

  .no-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    >img {
      max-width: 100%;
      max-height: 450px;
    }

    .plan-add-button {
      margin: 0;
    }
  }

  .plan-add-button {
    .add-button {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      line-height: 1;
      background-color: $primary-dark;
      border-color: $primary-dark;

      &:active {
        background-color: $primary-dark !important;
        border-color: $primary-dark !important;
      }
    }


    &.show {
      .dropdown-toggle {
        background-color: $primary-dark;
        border-color: $primary-dark;
      }
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;
      background-color: $primary-dark;
      border-color: $primary-dark;

      &:active {
        background-color: $primary-dark !important;
        border-color: $primary-dark !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .row-column {
    display: flex;
    flex-direction: column;

    .date-separator {
      width: 100%;
      color: gray;
      position: relative;
      display: flex;
      align-items: center;
      margin: 1rem 0;

      >span {
        text-align: center;
        white-space: nowrap;
        margin: 0 15px;
      }

      &::before,
      &::after {
        border-top: 1px solid lightgray;
        width: 100%;
        content: "";
        display: block;
      }
    }
  }
}

.wkt-item-single-compact {
  // font-size: $compact-font;
}

.workette-add-form.compact {
  padding-top: 0;
}