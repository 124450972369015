@import "./colors";
@import "./mixins";

.primary-button {
  @include primary-button;
}

.secondary-button {
  @include secondary-button;
}

.wkt-buttons {
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  .label {
    font-size: px-to-rem(12px);
    margin-left: 5px;
  }

  svg {
    color: color-opacity($primary-dark, 0.5);

    &.active {
      color: $primary-dark;
    }
  }
}
