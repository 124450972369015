.date-selector {
  background-color: unset !important;
  color: black !important;
  border-color: unset !important;
  border: unset !important;
  padding: 0 0 0 0 !important;
  box-shadow: none !important;

  &:after {
    position: absolute;
    left: 110px;
    top: 10px;
  }
  .datepicker-container {
    display: flex;
    position: relative;

    .help-tooltip {
      margin-left: 0.5rem;
      position: relative;
      right: 0;
    }
  }
}
.react-calendar {
  border: none;
  font-family: Manrope;
}
.simple {
  &:hover {
    background-color: #fff !important;
  }
}

// TODO: Today's date is currently highlighted yellow, might work better not highlighted
