@import "../../styles/colors";

.recommended-parent {
  background: $primary-gray;
  align-items: center;
  text-align: center;
  // margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border: green 1px solid;
  border-radius: 7px;
  white-space: nowrap;
  z-index: 999;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  gap: 0.5rem;

  img {
    height: 0.75rem;
  }

  .title-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      flex: 1;
    }
  }

  .parent-recommendation-buttons {
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 1;
  }
}