@import "../../../styles/mixins";

.inline-add-form {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  .add-form-input {
    &::placeholder {
      text-transform: capitalize;
    }
  }

  >div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .wtype-select {
    align-self: flex-start;
    position: relative;
    width: max-content;

    >select {
      height: calc(var(--base-font-size) * 3 - 2px);
    }
  }

  .title-input {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .form-group {
      margin-bottom: 0 !important;
    }
  }

  .open-modal {
    cursor: pointer;
    align-self: flex-start;
    position: relative;
    top: 10px;
  }

  .link-preview-img {
    @include thin-border-lightgray;
    display: inline-flex !important;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-left: 0.25rem;
    border-radius: 0.5rem;
    background: #f7f7f7;
    // max-width: 435px;
    // min-height: 305px;
    color: gray;

    .link-metada {
      display: flex;
      flex-direction: column;
    }

    @include larger-than-phone {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr minmax(200px, 1fr);
    }

    // &:hover {
    //   a {
    //     color: blue;
    //   }
    // }

    a {
      display: flex;
      flex-direction: column;
      // gap: 1rem;
      text-decoration: none;
      // color: gray;
      transition: 300ms color ease-in-out;
      text-decoration: underline;
    }

    .description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
    }

    img {
      width: 100%;
    }

    .footer-text {
      display: flex;
      flex-direction: column;
      margin-block: 0.5rem;
    }
  }
}

.wkt-details-view {
  .label-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 0px;
    color: #828282;
  }

  .ql-editor {
    word-break: break-word;
  }

  .rta {
    width: 100%;
  }
}

.small-modal {
  width: 400px !important;
}

.wkt-add-modal {

  .title-input-group {
    flex: 1;

    >.form-group {
      margin-bottom: 0 !important;
    }
  }

  .add-item-move-button {
    display: inline-flex;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    line-height: 1;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;

    &:hover {
      background-color: lightgray;
    }
  }


  .add-type-form {
    display: inline-flex;
    align-items: center;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    line-height: 1;

    &:hover {
      background-color: lightgray;
    }

    .type-input {
      cursor: pointer;
      outline: none;
      border: 0;
      padding: 0 0.25rem;
      border-radius: 0;
      background-color: transparent;
    }
  }

  .add-ritual-summary {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease-in-out;
    line-height: 1;
    display: inline-flex;


    &:hover {
      background-color: lightgray;
    }
  }

}

.add-recurring-popover {
  width: max-content !important;
  max-width: unset !important;
}