.mini-menu {
    background-color: unset !important;
    color: grey !important;
    border-color: unset !important;
    border: unset !important;
    padding: 0 0 0 0 !important;
    outline: none;
    box-shadow: none !important;


    &:after {
        display: none !important;
    }
}


.mini-menu-container {

    .dropdown-item {
        svg {
            margin-right: 0.5rem;
        }
    }

}