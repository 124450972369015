@import "../../../styles/colors";
@import "../../../styles/mixins";

.certify-bar {
  color: white;
  background-color: #ffd700;
  padding: 5px;
  margin: 10px;
  text-align: center;
  align-items: center;
}

.edit-backdrop {
  // background-color:#fff !important;
  opacity: 0.5 !important;
}

.focus-cards {
  padding: 0 !important;
}

.gap-5 {
  gap: 1rem 0.75rem;
}

.tab-content>.active>.col {
  padding-left: 0;
  padding-right: 0;
}

.perform-main-view-container {
  >.row {
    height: 100%;
  }

  .custom-certify-day {
    height: auto;
  }

  .datepicker-placeholder {
    display: flex;
    flex-direction: column;

    >span {
      &:first-child {
        width: 200px;
      }

      &:last-child {
        width: 75px;
      }
    }
  }

  .row {
    position: relative;

    .tour-toggle {
      position: absolute;
      top: 12px;
      right: 50px;
      z-index: 100;

      svg {
        position: fixed;
      }
    }
  }
}

.perform-tab-view {
  gap: 1rem 0.75rem;
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  @include larger-than-phone {
    &.row-lg {
      flex-direction: row;

      .focus-cards {
        width: calc(56.33% - 0.5rem);
      }

      .ritual-cards {
        width: calc(44% - 0.5rem);
      }
    }
  }

  .focus-cards {
    width: 100%;

    @include desktop {
      width: calc(56.33% - 0.5rem);
    }
  }

  .ritual-cards {
    width: 100%;

    @include desktop {
      width: calc(44% - 0.5rem);
    }
  }

  .flex-column {
    margin: 0;
  }

  .focus-cards,
  .ritual-cards {
    padding: 0;
  }
}

// .go-to-plan-button {
//   background-color: $primary-dark;
//   color: white;
//   border-radius: 2px;
//   padding: 4px;
//   width: 150px;
//   cursor: pointer;
//   font-size: 16px;
// }

.perform-main-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // padding-bottom: 5rem;

  >.top-row {
    margin: 0;
  }
}