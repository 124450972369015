.react-datepicker {
    &__close-icon::after {
        background-color: grey !important;
    }
    &__input-container {
        &:hover {
            .react-datepicker__close-icon {
                display: block;
            }
        }
        .react-datepicker__close-icon {
            display: none;
        }
    }
}