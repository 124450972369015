@import "./utils";

@mixin button {
  padding: 0.5rem 0.75rem;
  border-radius: px-to-rem(6px);
  color: white;
  border: 0;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  outline: none;
  margin: 0;

  >svg {
    width: px-to-rem(14px);
    aspect-ratio: 1/1;
  }
}

@mixin primary-button {
  @include button;
  background-color: $primary-color;

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background-color: $primary-active;
  }
}

@mixin secondary-button {
  @include button;
  background-color: $primary-gray;

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background-color: darken($primary-gray, 12%);
  }
}

@mixin whole-screen {
  width: 100vw;
  height: 100vh;
}

@mixin whole-parent {
  width: 100%;
  height: 100%;
}

@mixin grid-center {
  display: grid;
  place-content: center;
}

@mixin thin-border-lightgray {
  border: 1px solid lightgray;
}

@mixin form-input {
  @include thin-border-lightgray;
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  background: white;
  outline: none;
  transition: border 300ms ease-in-out;
  box-shadow: none;
  font-size: inherit;

  &:focus-visible {
    border: 1px solid $primary-color;
  }
}

/* HEADINGS */

@mixin headings-1 {
  font-size: px-to-rem(28px);
  line-height: 36px;
  font-weight: 580;
}

@mixin headings-2 {
  font-size: px-to-rem(20px);
  line-height: 28px;
  font-weight: 580;
}

@mixin headings-3 {
  font-size: px-to-rem(16px);
  line-height: 24px;
  font-weight: 580;
}

@mixin headings-4 {
  font-size: px-to-rem(14px);
  line-height: 20px;
  font-weight: 580;
}

@mixin headings-5 {
  font-size: px-to-rem(12px);
  line-height: 16px;
  font-weight: 580;
}

/* MEDIA QUERIES */
@mixin tablet {
  @media (min-width: 48.063em) and (max-width: 63.938em) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 63.938em) {
    @content;
  }
}

@mixin larger-than-phone {
  @media (min-width: 48em) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: 85.375em) {
    @content;
  }
}