@import "./colors";
@import "./utils";

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $text-white;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px $bg-dark;
  position: relative;
  padding: 1rem;
}
