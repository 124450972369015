.result-item {
  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__workset-icon {
    width: 6px;
    border-radius: 4px;
  }
  // &__expand{
  //   margin-right:16px;
  // }
}

.cb-placeholder {
  width: 1rem;
  height: 1rem;
}

.cb-selected-goal {
  appearance: none;
  background: white;
  outline: 1px solid lightgray;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  cursor: pointer;

  &::after {
    content: " ";
    position: relative;
    left: 35%;
    top: 15%;
    width: 30%;
    height: 55%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(50deg);
    display: none;
  }

  &:checked {
    background-color: var(--data-color, #0075ff);
    outline: 0;

    &:after {
      display: block;
    }
  }
}
