@import '../../../styles/colors';

.referral-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
        margin-bottom: 1rem;
    }

    .template {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        font-style: italic;
        margin-block: 1rem;
        background: $primary-lightgray;
        padding: 1rem;
    }
}