$comfortable-size: 1rem;
$compact-size: 0.5rem;

$side-nav-width: 250px;
$side-nav-width-toggled: 78px;
$main-div-width: calc(100vw - $side-nav-width);
$trophy-wall-width: 350px;

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.5rem;
}

.gap-6 {
  gap: 2rem;
}