@import '../../../../styles/colors';

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    >header {
        display: flex;
        flex-direction: column;
        max-width: 50ch;

        >h1 {
            color: $primary-color;
        }

        >h2 {
            color: #15162c;
        }
    }

    .feature {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        ul {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            li {
                display: flex;
                align-items: center;
                gap: 0.25rem;

                input[type="checkbox"] {
                    appearance: none;
                    outline: 0;
                    background: white;
                    height: 1.2rem;
                    width: 1.2rem;
                    border-radius: 100%;
                    position: relative;

                    &::after {
                        content: " ";
                        position: absolute;
                        left: 36%;
                        top: 15%;
                        width: 30%;
                        height: 55%;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(50deg);
                        display: none;
                    }

                    &:checked {
                        background-color: $primary-color;

                        &:after {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}